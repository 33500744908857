export function getBase64Sync(imgUrl) {
  return new Promise(function (resolve, reject) {
    // 一定要设置为let，不然图片不显示
    let image = new Image();
    // 解决跨域问题
    image.crossOrigin = "use-credentials";
    //图片地址
    image.src = imgUrl;
    // image.onload为异步加载
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      //图片后缀名
      let ext = image.src
        .substring(image.src.lastIndexOf(".") + 1)
        .toLowerCase();
      //图片质量
      let quality = 0.8;
      //转成base64
      let dataurl = canvas.toDataURL("image/" + ext, quality);
      //返回
      resolve(dataurl);
    };
  });
}

//在实际使用过程中遇到了一点问题就是客户在短时间内大量下载文件，导致浏览器崩溃，
//测试后发现是下载的word文件内存过大，原始的word模版只有几十k，导出的图片也只有200k，但下载后的文件高大10M
//搜索文件后发现通过canvas转base64的方式会有可能引起图片内存过大或者过小
//于是修改后采用了下面的方法转base64
export function getBase(imgUrl) {
 return new Promise((resolve, reject) => {
 window.URL = window.URL || window.webkitURL;
 var xhr = new XMLHttpRequest();
 xhr.open("get", imgUrl, true);
 // 至关重要
 xhr.responseType = "blob";
 xhr.onload = function () {
 if (this.status == 200) {
 //得到一个blob对象
 var blob = this.response;
 console.log("blob", blob);
 // 至关重要
 let oFileReader = new FileReader();
 oFileReader.onloadend = function (e) {
 // 此处拿到的已经是 base64的图片了
 base64 = e.target.result;
 // return base64;
 resolve(base64);
 // console.log("方式一》》》》》》》》》", base64);
        };
 oFileReader.readAsDataURL(blob);
      } else {
 reject();
      }
    };
 xhr.send();
  });
}
//导入需要的依赖包
import PizZip from "pizzip";
import docxtemplater from "docxtemplater";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

//将base64格式的数据转为ArrayBuffer
function base64DataURLToArrayBuffer(dataURL) {
  const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
  if (!base64Regex.test(dataURL)) {
    return false;
  }
  const stringBase64 = dataURL.replace(base64Regex, "");
  let binaryString;
  if (typeof window !== "undefined") {
    binaryString = window.atob(stringBase64);
  } else {
    binaryString = new Buffer(stringBase64, "base64").toString("binary");
  }
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
}

/**
 * 参数明细
 *  tempDocxPath 模板文件路径
 * wordData 导出数据
 * fileName 导出文件名
 * imgSize 自定义图片尺寸
 */
export const exportWord = (tempDocxPath, wordData, fileName, imgSize) => {
  //这里要引入处理图片的插件
  var ImageModule = require("docxtemplater-image-module-free");

  //   const expressions = require("angular-expressions");

  // 读取并获得模板文件的二进制内容
  JSZipUtils.getBinaryContent(tempDocxPath, function (error, content) {
    if (error) {
      throw error;
    }

    // 图片处理
    let opts = {};
    opts = {
      //图像是否居中
      centered: false,
    };
    opts.getImage = (chartId) => {
      return base64DataURLToArrayBuffer(chartId);
    };
    opts.getSize = function (img, tagValue, tagName) {
      if (imgSize.hasOwnProperty(tagName)) {
        return imgSize[tagName];
      } else {
        return [100, 50];
      }
    };
    let zip = new PizZip(content);
    let doc = new docxtemplater();
    doc.attachModule(new ImageModule(opts));
    doc.loadZip(zip);
    doc.setData(wordData);
    try {
      doc.render();
    } catch (error) {
      // 抛出异常
      let e = {
        message: error.message,
        name: error.name,
        stack: error.stack,
        properties: error.properties,
      };
      console.log(
        JSON.stringify({
          error: e,
        })
      );
      throw error;
    }
    let out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    saveAs(out, fileName);
  });
};